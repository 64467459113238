import React from 'react'

import { hooks } from '@front/volcanion'
import { BillingUtils } from '@front/volcanion/utils'

const withContainer = Component => ({ option }) => {
  const [{ record_id, isReadOnly }] = hooks.useFormState()

  const [order] = hooks.useModel('order', [record_id], { single: true, populate: ['quotation'] })

  const getEstimation = hooks.useEstimationSelector()

  const estimation = getEstimation(option?.commercial_package_id)

  const pricing = !!isReadOnly && !!record_id
    ? order?.quotation
    : estimation?.details?.pricing

  const rate = BillingUtils.getEstimationDisplay(pricing, false, 1)

  const mergedProps = {
    rate
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer

import React, { useCallback } from 'react'
import { I18n, hooks } from '@front/volcanion'

const withContainer = Component => ({ allowVehicleCount, ...rest }) => {
  const MAX_BOOKING_VEHICLES = hooks.useRelayConstant('MAX_BOOKING_VEHICLES')
  const { openNotification } = hooks.useNotification()
  const [{ service, vehicle_count }, setFormState] = hooks.useFormState()
  const [commercial_package] = hooks.useFieldValues(['commercial_package'])

  const packages = hooks.useMethodListenerResult('ridemode', 'package_estimations')
  const onDecrement = useCallback(() => {
    if (vehicle_count - 1 >= 1) setFormState({ vehiclle_count: vehicle_count - 1 })
    else openNotification(I18n.t('vehicle.number.too_low'), { variant: 'error' })
  }, [vehicle_count, setFormState, openNotification])

  const onIncrement = useCallback(() => {
    if (vehicle_count + 1 <= MAX_BOOKING_VEHICLES) setFormState({ vehicle_count: vehicle_count + 1 })
    else openNotification(I18n.t('vehicle.number.too_high', { max: MAX_BOOKING_VEHICLES }), { variant: 'error' })
  }, [vehicle_count, setFormState, openNotification, MAX_BOOKING_VEHICLES])

  const hideVehicleCount = !service || !allowVehicleCount

  const cp_ids = _.map(packages, 'commercial_package_id')

  const mergedProps = {
    cp_ids,
    hideVehicleCount,
    commercial_package,
    service,
    vehicle_count,
    onDecrement,
    onIncrement
  }

  return (
    <Component {...mergedProps} {...rest} />
  )
}

export default withContainer
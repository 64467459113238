
import React, { useCallback } from 'react'
import { hooks, I18n, CDNSVG, CDNImage } from '@front/volcanion/'
import { useTheme } from '@mui/styles'

import { Fade, Box, AppBar, Button, Divider, Grid, IconButton, Toolbar, Typography } from '@mui/material'
import { AccountCircle, ExitToApp } from '@mui/icons-material'

const TopbarComponent = ({ sendContactMail, first_name, navigate, hasCompany, isAdmin, version, user_id, ...props }) => {
  const logout = useCallback(() => {
    localStorage.clear()
    window.location.reload()
  }, [])

  const pathname = _.get(window, 'location.pathname')
  const { palette } = useTheme()

  return (
    <AppBar position='static' sx={{ backgroundColor: 'white', zIndex: 'modal', position: 'absolute', top: 0, maxHeight: 80 }}>
      <Toolbar>
        <Grid container spacing={1} alignItems='center'>
          <Grid item xs={1}>
            <CDNImage name={'logo'} category={'images'} style={{ maxHeight: 60 }} />
          </Grid>

          <Grid item xs>
            <Box display={'flex'} justifyContent='space-around'>
              <Button size='small' variant={pathname === '/booking' || pathname === '/' ? 'contained' : 'text'} color='primary' onClick={() => navigate('/booking')}>
                <Typography fontSize='1vw'>{I18n.t('order.action')}</Typography>
              </Button>
              <Button sx={{ ml: 1, mr: 1 }} size='small' variant={_.includes(['/profile'], pathname) ? 'contained' : 'text'} color='primary' onClick={() => navigate('/profile')}>
                <Typography fontSize='1vw'>{I18n.t('user.space')}</Typography>
              </Button>
              <Box hidden={!hasCompany}>
                <Button size='small' variant='text' disabled={!isAdmin} variant={pathname.indexOf('admin') !== -1 ? 'contained' : 'text'} color='primary' onClick={() => navigate('/admin')}>
                  <Typography fontSize='1vw'>{I18n.t('admin.space')}</Typography>
                </Button>
              </Box>
            </Box>
          </Grid>

          <Grid item xs container justifyContent='flex-end' >
            <Grid container item xs direction='row-reverse'>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <IconButton onClick={sendContactMail} sx={{ fontSize: '1.2vw' }}>
                  <CDNSVG name='chat' fill={palette.primary.main} category={'icons'} style={{ fontSize: '1.2vw' }} />
                </IconButton>
                <Typography color='primary' variant={'h5'} fontSize={'1.2vw'}> {version} </Typography>
                <IconButton disabled color='primary' sx={{ fontSize: '1.2vw' }}>
                  <AccountCircle fontSize='inherit' />
                </IconButton>
                <Fade in={!!first_name} timeout={3000}>
                  <Typography align='center' fontSize={'1.2vw'} color='primary' align='center' sx={{ fontWeight: 300, overflow: 'auto' }}>
                    {I18n.t('user.hello', { name: first_name })}
                  </Typography>
                </Fade>
                <Divider orientation='vertical' sx={{ height: 30, ml: 2, mr: 2 }} />
                <IconButton onClick={logout} sx={{ color: 'red', p: 0, m: 0 }} size='large'>
                  <ExitToApp fontSize='inherit' />
                </IconButton>
              </Box>

            </Grid>

          </Grid>

        </Grid>
      </Toolbar >
    </AppBar >
  )
}

export default TopbarComponent

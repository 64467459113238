import React from 'react'
import { hooks, I18n } from '@front/volcanion'

import { Box, Icon, Typography } from '@mui/material'
import { MapFactory } from '@front/squirtle/'

const MeetingPointCard = ({ meeting_point_translated }) => {
  const [reservation_type, reservation_info] = hooks.useGlobalFieldValues('order_default_form', ['reservation_type', 'reservation_info'])

  return (
    <Box>
      <Box display={'flex'}>
        <Icon color='primary'>{'tour'}</Icon>
        <Typography ml={1} variant={'h6'} color='primary'>{`${I18n.t('address.rdv')}:`}</Typography>
      </Box>
      <Typography>{meeting_point_translated}</Typography>
      {!!reservation_type && <Typography>{I18n.t(`number_of.${reservation_type}`, { info: reservation_info || '' })}</Typography>}
    </Box>
  )
}

const MapBackgroundComponent = ({
  enableSourceMeeting,
  enableDestinationMeeting,
  source_coordinates,
  destination_coordinates,
  source_meeting_point_coordinates,
  source_meeting_point_translated,
  destination_meeting_point_coordinates,
  destination_meeting_point_translated,
  outbound_directions
}) => <>
    <MapFactory.MapContainer
      mapStyle={{
        position: 'fixed',
        top: 0,
        width: '100%',
        height: '100%',
        zIndex: 0
      }}
      mapId='background_map'
    >
      <MapFactory.SuperLayer fitBounds>

        <MapFactory.Polyline positions={outbound_directions} />

        <MapFactory.Marker
          position={source_coordinates}
          styles={{ icon: 'login', color: 'green' }}
        >
          {!!enableSourceMeeting &&
            <MapFactory.Popup>
              <MeetingPointCard meeting_point_translated={source_meeting_point_translated} />
            </MapFactory.Popup>
          }
        </MapFactory.Marker>


        <MapFactory.Marker
          position={destination_coordinates}
          styles={{ icon: 'login', color: 'red' }}
        >
          {!!enableDestinationMeeting &&
            <MapFactory.Popup>
              <MeetingPointCard meeting_point_translated={destination_meeting_point_translated} />
            </MapFactory.Popup>
          }
        </MapFactory.Marker>

        {!!enableSourceMeeting && <MapFactory.Marker
          position={source_meeting_point_coordinates}
          styles={{ icon: 'flag', color: 'green' }}
        >
          <MapFactory.Popup>
            <MeetingPointCard meeting_point_translated={source_meeting_point_translated} />
          </MapFactory.Popup>
        </MapFactory.Marker>
        }

        {!!enableDestinationMeeting && <MapFactory.Marker
          position={destination_meeting_point_coordinates}
          styles={{ icon: 'flag', color: 'red' }}
        >
          <MapFactory.Popup>
            <MeetingPointCard meeting_point_translated={destination_meeting_point_translated} />
          </MapFactory.Popup>
        </MapFactory.Marker>
        }

      </MapFactory.SuperLayer >
    </MapFactory.MapContainer>
  </>

export default MapBackgroundComponent



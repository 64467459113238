import React, { useEffect } from 'react'

import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const [selectedCompany, setSelectedCompany] = hooks.useGlobalState('selectedCompany')
  const [, setAdministrationLevel] = hooks.useGlobalState('administration_level')
  const [user] = hooks.useActiveUser({ populate: ['user_companies.company'] })
  const [company] = hooks.useModel('company', [selectedCompany], { single: true })
  const options = _.compact(_.map(_.get(user, 'user_companies'), elem => !!elem.administration_level && elem?.company?.company_id
    ? _.get(elem, 'company.company_id')
    : null
  ))

  useEffect(() => {
    const administration_level = _.find(user?.user_companies, ['company.company_id', selectedCompany])?.administration_level
    setAdministrationLevel(administration_level)
  }, [selectedCompany])


  const mergedProps = {
    options,
    name: _.get(company, 'name'),
    hasMultiple: _.size(options) > 1,
    selectedCompany,
    setSelectedCompany
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer

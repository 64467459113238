import React, { useCallback } from 'react'

import { hooks } from '@front/volcanion'
import { Loader } from '@front/squirtle'

const withContainer = Component => ({ goBackPath, ...props }) => {
  const navigate = hooks.useNavigate()
  const requestSubmit = hooks.useFormFunction('requestSubmit')
  const isReady = hooks.useFormStatus('isReady')
  const [isReadOnly, setReadOnly] = hooks.useFormState('isReadOnly')
  const [isManagingRecord] = hooks.useFormState('isManagingRecord')

  const onGoBack = useCallback(() => navigate(!!goBackPath ? goBackPath : -1), [goBackPath])

  const mergedProps = {
    isReadOnly,
    isManagingRecord,
    requestSubmit,
    setReadOnly,
    onGoBack
  }

  return (
    <Loader isLoading={!isReady}>
      <Component {...mergedProps} {...props} />
    </Loader>
  )
}

export default withContainer


import React from 'react'
import { I18n } from '@front/volcanion'

import { Typography, Box, Icon, IconButton, Button, Grid } from '@mui/material'

import { DirectionsCarFilledOutlined } from '@mui/icons-material'

const CustomHeader = ({ icon, label, value, onClick, selected, ...props }) =>
  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
    <Button
      fullWidth
      variant={selected === value ? 'contained' : 'outlined'}
      sx={{ textTransform: 'none' }}
      startIcon={
        <Icon sx={{ color: selected === value ? 'extraWhite' : 'primary.main', fontSize: '2.5vw' }} baseClassName='material-icons'> {icon} </Icon>
      }
      onClick={() => onClick(value)}
      size={'small'}
      {...props}
    >
      <Typography sx={{ fontSize: '1.3vw', letterSpacing: 5, textAlign: 'center' }} noWrap>
        {label}
      </Typography>
    </Button>
  </Box>

const OrderHeader = ({ onChange, value = 1, onQuickOrderChange, ...props }) => <>
  <Box sx={{ display: 'flex', p: 2, boxShadow: 6 }}>
    <Grid container justifyContent='center' alignItems='center' spacing={1}>
      <Grid item xs>
        <CustomHeader label={I18n.t('order.in_progress.label', { count: -1 })} value={1} selected={value} icon='route' onClick={onChange} />
      </Grid>
      <Grid item xs>
        <CustomHeader label={I18n.t('order.planneds.label', { count: -1 })} value={2} selected={value} icon='update' onClick={onChange} />
      </Grid>
      <Grid item xs>
        <CustomHeader label={I18n.t('history.label', { count: 1 })} value={3} selected={value} icon='history' onClick={onChange} />
      </Grid>
    </Grid>
    <Box sx={{ ml: 1, display: 'none', alignItems: 'center' }}>
      <IconButton onClick={onQuickOrderChange} sx={{ color: 'primary.main', border: 1, borderRadius: 3, borderColor: 'primary.main' }}>
        <DirectionsCarFilledOutlined fontSize='medium' />
      </IconButton>
    </Box>
  </Box>
</>


export default React.memo(OrderHeader)

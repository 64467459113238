import React from 'react'
import { I18n } from '@front/volcanion'

import { ModelCardGridField, TextField } from '@front/squirtle'
import { CardContent, CardHeader, CardFooter } from '@front/squirtle/components/Card/component'

import { Box, Grid, Typography, IconButton, Icon } from '@mui/material'

import { Icon as PackageCardIcon, Content as PackageCardContent, Footer as PackageCardFooter } from './Card'

const PackageForm = ({
  isLoading,
  cp_ids,
  getPackagePrice,
  commercial_package,
  vehicle_count,
  onDecrement,
  onIncrement,
  hideVehicleCount,
  enableFooter = true,
}) => <>
    <Box sx={{ overflow: "auto" }}>
      <Grid container spacing={1} rowSpacing={1.5} justifyContent='center' >
        <Grid item xs={12}>
          <Box sx={{ borderLeft: 4, borderColor: 'primary.main', mt: 1, }}>
            <Typography sx={{ fontSize: 'h5.fontSize', color: 'primary.main', p: 2, fontWeight: 300 }}>
              {I18n.t('vehicle.type')}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={11}>
          <Box sx={{ height: 400, overflow: 'auto', p: 2 }}>
            <ModelCardGridField
              name={'commercial_package'}
              model_name={'commercialpackage'}
              labelKeys={['name']}
              cardLabelProps={{ sx: { fontWeight: 500, fontSize: 'subtitle.fontSize', textAlign: 'center' } }}
              config={{
                ids: cp_ids,
                sortBy: 'display_index',
                sortDirection: ['DESC']
              }}
              cardProps={{
                dense: true,
                titleProps: { sx: { textAlign: 'center', width: '100%' } },
                getContentStyle: (option, theme, selectorProps) => (selectorProps.isOptionSelected(option) && !!commercial_package
                  ? {
                    backgroundColor: 'rgb(241, 241, 241)'
                  }
                  : {})
              }}
              colCount={1}
              selectFirst={false}
              allowNone={false}
            >
              <CardHeader><PackageCardIcon /></CardHeader>
              <CardContent><PackageCardContent /></CardContent>
              {!!enableFooter && <CardFooter><PackageCardFooter /></CardFooter>}
            </ModelCardGridField>
          </Box>
        </Grid>
        <Grid container item xs={9} alignItems='center'>
          <Grid item xs hidden={hideVehicleCount}>
            <Typography sx={{ fontSize: 'h6.fontSize', color: 'text.label', fontWeight: 200 }}>
              {I18n.t('vehicle.number.label')}
            </Typography>
          </Grid>
          <Grid item xs={4} hidden={hideVehicleCount}>
            <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
              <Grid item xs={4}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton sx={{ color: 'grayLight' }} onClick={onDecrement}>
                    <Icon>{'arrow_back_ios_new'}</Icon>
                  </IconButton>
                  <Box sx={{ ml: 1, mr: 1 }}>
                    <Typography sx={{ fontWeight: 200, color: 'text.label', fontSize: 22, textAlign: 'center' }}>{vehicle_count}</Typography>
                  </Box>
                  <IconButton sx={{ color: 'grayLight' }} onClick={onIncrement}>
                    <Icon>{'arrow_forward_ios_new'}</Icon>
                  </IconButton>
                </Box>
              </Grid>
              <Grid hidden item xs>
                <TextField name='vehicle_count' />
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  </>

export default React.memo(PackageForm)

import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'

import Callbacks from './callbacks'
import UserUtils from '@front/volcanion/utils/user'

const withContainer = Component => props => {
  const navigate = hooks.useNavigate()
  const version = hooks.useVersion()
  const { CLIENT_COMMERCIAL_NAME, CLIENT_CONTACT_EMAIL } = hooks.useRelayConstants(['CLIENT_COMMERCIAL_NAME', 'CLIENT_CONTACT_EMAIL'])
  const [user] = hooks.useActiveUser({ populate: ['user_companies', 'auth', 'customerinfo', 'info'] })

  const sendContactMail = useCallback(Callbacks.sendContactMailHandler(CLIENT_CONTACT_EMAIL, CLIENT_COMMERCIAL_NAME, user), [CLIENT_CONTACT_EMAIL, CLIENT_COMMERCIAL_NAME, user])

  const hasCompany = !_.isEmpty(_.get(user, 'user_companies'))
  const isAdmin = !_.isEmpty(_.filter(_.get(user, 'user_companies'), companyuser => !!companyuser.administration_level))
  const mergedProps = {
    version,
    first_name: _.get(user, 'info.first_name'),
    user_id: user?.user_id,
    hasCompany,
    isAdmin,
    navigate,
    sendContactMail
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer

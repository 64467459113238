import React from 'react'

import { Box } from '@mui/material'
import { AccountBalanceOutlined } from '@mui/icons-material'
import { useTheme } from '@emotion/react'

import { ModelMenuSelectorComponent } from '@front/squirtle'

const AdminHeader = ({ selectedCompany, setSelectedCompany, options, hasMultiple }) => {
  const extraContainerStyle = !!hasMultiple && { '&:hover': { bgcolor: 'primary.light' } }
  const theme = useTheme()
  return <>
    <Box sx={{
      width: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gcolor: 'extraWhite',
      borderColor: 'primary.main',
      m: 1,
      p: 1,
      borderBottom: 1,
      borderTop: 1,
      ...extraContainerStyle
    }}>
      <AccountBalanceOutlined size={'large'} sx={{ color: 'primary.main' }} />
      <ModelMenuSelectorComponent
        model_name={'company'}
        labelKeys={['name']}
        valueDecoratorProps={{
          noWrap: true,
          sx: {
            ml: 2,
            color: 'primary.main',
            fontSize: 20,
            letterSpacing: 3,
            WebkitTextFillColor: theme.palette.primary.main,
          }
        }}
        value={selectedCompany}
        onChange={setSelectedCompany}
        config={{ ids: options }}
        selectProps={!hasMultiple ? { IconComponent: null } : {}}
        disableUnderline
        selectFirst
      />
    </Box>
  </>
}

export default React.memo(AdminHeader)

import React from 'react'

import { Box, Typography } from '@mui/material'

const Footer = ({ isLoading, rate }) =>
  <Box sx={{ width: 1, display: 'flex', justifyContent: 'flex-end' }}>
    <Typography variant={'h6'} color={'primary'} textAlign={'center'}>
      {rate}
    </Typography>
  </Box>

export default React.memo(Footer)

import React from 'react'

import { CDNImage, CDNSVG } from '@front/volcanion'

import { Box, Grid, Typography } from '@mui/material'

const SelectedCard = ({
  client_type_icon,
  name_translated,
  summary_translated,
  max_load,
  displayDescription
}) =>
  <Box sx={{ border: 1, borderColor: 'primary.main', borderRadius: 2, p: 1 }} spacing={1}>
    <Grid container justifyContent='center'>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CDNImage category='packages' name={client_type_icon} style={{ width: 150 }} />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography sx={{ fontSize: 'h6.fontSize', color: 'primary.main', mr: 2 }}> {name_translated}</Typography>
          {!!displayDescription && <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CDNSVG name='account-b2c' fill='primary.main' category={'icons'} sx={{ fontSize: '15px' }} />
            <Box sx={{ ml: 2 }}>
              <Typography sx={{ fontSize: 'caption.fontSize', textAlign: 'center' }}>{max_load}</Typography>
            </Box>
          </Box>}
        </Box>
        {!!displayDescription && <Typography sx={{ fontSize: 'caption.fontSize', textAlign: 'center' }}>{summary_translated}</Typography>}
      </Grid>
    </Grid>
  </Box>

export default SelectedCard

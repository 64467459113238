import React from 'react'

import { hooks } from '@front/volcanion'

const withContainer = Component => ({ cp_id }) => {
  const [commercial_package] = hooks.useModel('commercialpackage', [cp_id], { single: true })

  const mergedProps = {
    hasPackage: !!commercial_package,
    client_type_icon: _.get(commercial_package, 'client_type_icon') || 'classic',
    name_translated: _.get(commercial_package, 'name_translated'),
    summary_translated: _.get(commercial_package, 'summary_translated'),
    title_translated: _.get(commercial_package, 'title_translated'),
    max_load: _.get(commercial_package, 'max_load'),
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer

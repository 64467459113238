import React from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => ({ ...rest }) => {
  const [user] = hooks.useActiveUser()
  const [service] = hooks.useFieldValues(['service'])
  const companyuserservices = hooks.useSearchListenerResult('companyuserservices', 'user_accounts')
  const mergedProps = {
    user_id: user?.user_id,
    service,
    companyuserservices,
  }

  return <Component {...mergedProps} {...rest} />
}

export default withContainer
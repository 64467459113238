import _ from "lodash"

class Callbacks {
  static sendContactMailHandler(CLIENT_CONTACT_EMAIL, CLIENT_COMMERCIAL_NAME, user) {
    return function sendContactMail() {
      if (!user) return
      const first_name = _.get(user, 'info.first_name')
      const last_name = _.get(user, 'info.last_name')
      const client_number = _.get(user, 'customerinfo.client_number')
      const gsm = _.get(user, 'auth.gsm')
      window.location.assign(
        `mailto: ${CLIENT_CONTACT_EMAIL} 
        ?Subject= ${CLIENT_COMMERCIAL_NAME} - Contact
        &body=Plateform: Web booker
        %0d%0a Client: ${client_number} - ${first_name} ${last_name}
        %0d%0a Téléphone: ${gsm}
        %0d%0a Message:
        `
      )
    }
  }
}

export default Callbacks

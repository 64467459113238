import React from 'react'

import { Box, Grid, Typography } from '@mui/material'
import { CDNSVG } from '@front/volcanion'

const Content = ({ option, display_capacity, ...props }) =>
  <Box sx={{ width: 1 }}>
    <Grid container justifyContent='center' alignItems='center' spacing={2}>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography sx={{ fontSize: 'h6.fontSize', color: 'primary.main', mr: 2 }}> {_.get(option, 'name_translated')}</Typography>
          {!!(option?.load_type === 'user') &&
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CDNSVG name='account-b2c' fill='primary.main' category={'icons'} sx={{ fontSize: '15px' }} />
              <Box sx={{ ml: 2 }}>
                <Typography sx={{ fontSize: 'caption.fontSize', textAlign: 'center' }}>{_.get(option, 'max_load')}</Typography>
              </Box>
            </Box>
          }
        </Box>
        <Typography sx={{ fontSize: 'caption.fontSize', textAlign: 'center' }}>{_.get(option, 'summary_translated')}</Typography>
      </Grid>
    </Grid>
  </Box>

export default React.memo(Content)

import React from 'react'

import { CDNImage } from '@front/volcanion'
import { Box } from '@mui/material'

const PackageIcons = ({ option, ...props }) =>
  <Box sx={{ p: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <CDNImage category='packages' name={option?.client_type_icon} style={{ height: '80px', width: 'auto' }} />
  </Box>

export default React.memo(PackageIcons)

import React, { useCallback } from 'react'
import { I18n } from '@front/volcanion'

import _ from 'lodash'

import { ModelMenuSelectorField, } from '@front/squirtle'
import { Box } from '@mui/material'

const AccountSelector = ({
  user_id,
  hideIfEmpty = false,
  companyuserservices,
  service,
  company,
  watchers = [],
  config = {},
  ...rest
}) => (
  <Box hidden={!!hideIfEmpty && _.isEmpty(companyuserservices)} sx={{ flexDirection: 'row' }}>
    <ModelMenuSelectorField
      label={"Compte: "}
      name={'service'}
      model_name={'companyuserservices'}
      config={{
        initial_filter: {},
        forced_filter: {
          companyuser: { status: [{ name: 'active' }], user: user_id, company: company },
          companyservice: { status: [{ name: 'active' }] },
        },
        validate_filter: useCallback(() => !!user_id, [user_id]),
        watchers: _.flatten([[user_id], watchers]),
        populate: ['companyservice.contract'],
        mutator: (companyuserservice) => ({
          label: `${_.get(companyuserservice, 'companyservice.contract.name')} - ${_.get(companyuserservice, 'companyservice.name')}`,
          value: _.get(companyuserservice, 'companyservice.companyservice_id'),
          companyuserservice_id: _.get(companyuserservice, 'companyuserservice_id'),
        }),
        finalMutator: (options) => _.uniqBy(options, 'value'),
        search_id: 'user_accounts',
        ...config
      }}
      selectKeys={['value']}
      searchMode={'search'}
      noneLabel={I18n.t('account.personal')}
      noneFirst
      variant='outlined'
      {...rest}
    />
  </Box >
)


export default React.memo(AccountSelector)

import React from 'react'
import { hooks } from '@front/volcanion'

const isSamePlace = address => _.isEqual(address?.coordinates, address?.meeting_point_coordinates)

const withContainer = Component => props => {
  const [source] = hooks.useGlobalFormState('order_default_form', 'details.source')
  const [destination] = hooks.useGlobalFormState('order_default_form', 'details.destination')
  const values = hooks.useGlobalFormValues('order_default_form')

  const enableSourceMeeting = !!_.has(source, 'meeting_point_coordinates') && !isSamePlace(source)
  const enableDestinationMeeting = !!_.has(destination, 'meeting_point_coordinates') && !isSamePlace(destination)

  const [outbound_directions] = hooks.useSelectedEstimationDirections(values?.commercial_package)

  const source_coordinates = source?.coordinates
  const destination_coordinates = destination?.coordinates
  const source_meeting_point_coordinates = source?.meeting_point_coordinates
  const source_meeting_point_translated = source?.meeting_point_translated
  const destination_meeting_point_coordinates = destination?.meeting_point_coordinates
  const destination_meeting_point_translated = destination?.meeting_point_translated

  const mergedProps = {
    outbound_directions,
    enableSourceMeeting,
    enableDestinationMeeting,
    source_coordinates,
    destination_coordinates,
    source_meeting_point_coordinates,
    source_meeting_point_translated,
    destination_meeting_point_coordinates,
    destination_meeting_point_translated
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer


import React from 'react'
import { CDNSVG } from '@front/volcanion'

import { Grid, Box, IconButton, Typography, Icon } from '@mui/material'

const Actions = ({ enableCancelEditAction = true, isReadOnly, isManagingRecord, setReadOnly, requestSubmit }) =>
  <Grid container justifyContent='flex-end' spacing={2}>
    <Grid item xs={4} hidden={!isReadOnly || !isManagingRecord}>
      <IconButton color='primary' sx={{ fontSize: 33 }} onClick={() => setReadOnly(false)}>
        <CDNSVG name='edit' fill='primary.main' category={'icons'} />
      </IconButton>
    </Grid>
    <Grid item xs={4} hidden={!!isReadOnly}>
      <IconButton color='primary' sx={{ fontSize: 33 }} onClick={() => requestSubmit()}>
        <Icon>{'save'}</Icon>
      </IconButton>
    </Grid>
    <Grid item xs={4} hidden={!!isReadOnly || !enableCancelEditAction}>
      <IconButton color='error' sx={{ fontSize: 33 }} onClick={() => setReadOnly(true)}>
        <Icon>{'close'}</Icon>
      </IconButton>
    </Grid>
  </Grid>

const IconComponent = ({ icon, iconProps, ...props }) => <>
  <Icon
    size={'small'}
    color={'primary'}
    baseClassName='material-icons-outlined'
    className={icon}
    sx={{ fontSize: 33 }}
    {...iconProps}
  >
    {icon}
  </Icon>
</>

const FormHeader = ({ title, onGoBack, enableActions = true, enableGoBack = true, isSvg = false, name, ...props }) =>
  <Box sx={{ boxShadow: 3, borderRadius: 2, p: 2 }}>
    <Box display='flex' justifyContent={'space-between'}>
      <Box display={'flex'} alignItems={'center'}>
        {!!enableGoBack && <IconButton onClick={onGoBack} mr={2}><Icon color='primary'>{'arrow_back'}</Icon></IconButton>}
        {!!isSvg ? <CDNSVG name={name} category={'icons'} /> : <IconComponent {...props} />}
        <Typography color='primary' fontSize={21} ml={1}>{title}</Typography>
      </Box>
      <Box mx={2}>
        {!!enableActions && <Actions {...props} />}
      </Box>
    </Box>
  </Box >


export default React.memo(FormHeader)
